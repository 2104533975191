<template>
  <div class="wrapper">
    <el-carousel class="slideBox" height="100%" :interval="5000" arrow="never">
      <!-- <el-carousel-item> -->
      <div class="slideBox1">
        <div class="content animate__animated animate__fadeIn">
          <div class="title1">高精度音频定位技术提供商</div>
          <div class="title2">
            沧穹科技为音频室内定位技术提供商，专注于核心IP及芯片研发，可为下游合作伙伴<br />
            提供底层软硬件产品及技术支持服务，产品主要包括芯片、模组、开发板和SDK，并<br />
            可为各类行业定位场景集成服务商的技术支持服务。
          </div>
        </div>
      </div>
      <!-- </el-carousel-item> -->
    </el-carousel>
    <div class="wrapper-content">
      <div class="row_content">
        <div class="content">
          <div style="text-align: center; margin: 80px 0"><img src="/images/products/p_1.jpg" width="1044px" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
/*slideBox 开始*/
.slideBox {
    width: 100%;
    height: 581px;
    overflow: hidden;
    z-index: 1;
}

.slideBox1 {
    width: 100%;
    height: 100%;
    background-image: url("/images/products/slide_1.jpg");
    background-position: 0px 0px;
    background-size: cover;
    z-index: 1;
    .content {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        text-align: left;
        
        @media screen and (max-width: 1200px) {
            .header-info {
                width: 100%;
            }
        }

        .title1 {
            font-size: 36px;
            margin-left: 420px;
            margin-top: 220px;
            font-family: "Source Han Sans", Geneva, sans-serif;
        }
        .title2 {
            font-size: 14pt;
            margin-left: 420px;
            margin-top: 30px;
            font-family: 微软雅黑; 
            line-height: 1.5; 
        }
    }
}
</style>
